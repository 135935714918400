.about{
    display: flex;
    flex-direction: column;
    /* padding-bottom: 1.5rem; */
}
.header{
    padding: 1.5rem 3rem 0px;
    @media screen and (max-width:800px) {
        padding: 1.5rem 1.5rem;
    }
    p:first-of-type{
        font-size: 35px;
        font-weight: 700;
        @media screen and (max-width:800px) {
            font-size: 1.5rem;
        }
    }

}
