.contactHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 2rem 3rem 1rem;
    @media screen and (max-width: 800px) {
        &{
            flex-direction: column;
            align-items: flex-start;
            padding: 1.5rem 1.5rem 0px;
        }    
    }
    & p{
        font-size: 24px;
        font-weight: 700;
        @media screen and (max-width: 800px) {
            & {
                font-size: 20px;
            }
        }
    }   
    & span{
        color: #929090;
        font-size: 24px;
        font-weight: 600;
        @media screen and (max-width: 800px) {
            &{
                font-size: 20px;
            }
        }
    }
}