.frame{
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 2rem;
    border-radius: 30px;
    border: 4px solid #000;
    position: relative;
    gap: 1.5rem;
    border-radius: 15px;
    @media screen and (max-width:800px) {
        padding: 1.5rem;
    }
    .title{
        padding: .5rem 1rem;
        text-align: center;
        position: absolute;
        top: 0;
        width: 600px;
        left: 50%;
        border-radius: 30px;
        background: #000;
        color: #FFF;
        transform: translate(-50%, -50%);
        font-size: 28px;
        font-weight: 700;
        @media screen and (max-width: 800px) {
            width: fit-content;
            padding:.2rem 2rem .5rem ;
            font-size: 20px;    
        }
    }
    .text{
        padding-top: 1rem;
        p{
            font-size: 18px;
            font-weight: 400;
            @media screen and (max-width: 800px) {
                font-size: 16px;    
            }
        }
    }
    .button{
        display: flex;
        align-self: stretch;
        justify-content: center;
        align-items: center;
        button{
            padding: 15px 40px;
            @media screen and (max-width: 800px) {
                padding: 6px 20px ;
                font-size: 18px;    
            }
        }
    }
}