.items {
  margin: 1.5rem 0;
  display: flex;
  align-self: stretch;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem 2rem 0rem;
  @media screen and (max-width: 800px) {
    & {
      padding: 1.5rem 1.5rem 0px;
      margin-top: 8px;
      gap: 5%;
    }
  }
}
.bottom{
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 10px;
  padding: 0px 3rem;
  @media screen and (max-width: 800px) {
    padding: 0px 1.5rem;
  }
  .text{
    display: flex;
    align-items: center;
    justify-content: center;
    & p{
      color: #929090;
      font-size: 18px;
      font-weight: 500;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }
  .next{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & button{
      background-color: initial;
      border: 0;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }
}
