.button1{
    text-decoration: none;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #000; 
    color: #000;
    text-align: center;
    transition: .4s ease-in-out;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    background-color: inherit;
    &:hover{
        background-color: #000;
        color: #fff;
    }
    @media screen and (max-width: 768px) {
        & {
            font-size: 20px;
            line-height: 30px;
            border-radius: 12px;
            padding: 10px 20px;
        }          
    }
    
}

.button2{
    border-radius: 15px; 
    color: #fff;
    transition: .3s;
    background-color: #000;
    @media screen and (max-width: 768px) {
        & {
            min-width: auto;
            font-size: 16px;
            line-height: 38px;
            border-radius: 10px;
            padding: 0px 30px;
        }          
    }   
}

.title{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
    
    & p{
        & span{
            color: #929090;
            font-weight: 600;
        }

        font-weight: 600;
        font-size: 28px;
        line-height: 48px;
        @media screen and (max-width: 768px) {
            & {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
            }
            & span{
                display: block;
                font-size: 20px;
            }         
        }   
    }

    & a{
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        text-decoration: none;
        color: #000000;
        @media screen and (max-width: 768px) {
            & {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                display: block;
                position: absolute;
                bottom: 10px;
                right: 7%;
            }
                     
        }
    }
    
}

.padding5{
    padding-left: 5% !important;
    padding-right: 5% !important;
    @media screen and (max-width: 768px) {
        & {
            padding-left: 7% !important;
            padding-right: 7% !important;
        }          
    }    
}

.header{
    width: 100vw;
    display: flex;
    align-items: center;
    &__logo{
        width: 50%;
        @media screen and (max-width: 768px) {
            & {
                width: 100%;
            }          
        }
    }
    &__title{
        font-weight: 900;
        font-size: 64px;
        line-height: 96px;
        @media screen and (max-width: 768px) {
            & {
                font-weight: 900;
                font-size: 24px;
                line-height: 36px;
            }          
        }
    }
    background: linear-gradient(180deg, #99CCF9 0%, rgba(154, 205, 251, 0.76) 100%);
}


.card{
    overflow: hidden;
    min-height: 20rem;
    width: 17.5rem;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
        & {
            width: 10rem;
            min-height: auto;
        }             
    }
    &__image{
        height: 24rem;
        background: rgba(218, 213, 213, 0.4);
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
            & {
                height: 13rem;
            }
                     
        }
        & img{
            width: 100%;
            pointer-events: none;
            height: 100%;
        }
    }
    &__title{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        @media screen and (max-width: 768px) {
            & {
                font-size: 14px;
                line-height: 21px;       
            }             
        }   
    }
    &__button{
        color: #929090;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        @media screen and (max-width: 768px) {
            & {
                font-size: 14px;
                line-height: 21px;       
            }             
        }
    }
    & a{
        font-size: smaller;
        text-decoration: none;
        & i{
            font-size: smaller;
            padding: 5px;
        }
    }

}