.products{
    display: flex;
    flex-direction: column;
    padding: 1.5rem 3rem 0px;
    .content{
        margin-top: 4rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 4rem;
        @media screen and (max-width:800px) {
            padding: 0px 1.5rem 0px;
        }
    }
    @media screen and (max-width:800px) {
        padding: 1.5rem 0px 0px;
    }
}
.productsList{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 0px 1rem;
    flex-wrap: wrap;
}
.header{
    /* padding: 1.5rem 3rem; */
    @media screen and (max-width:800px) {
        padding: 0px 1.5rem;
    }
    p:first-of-type{
        font-size: 35px;
        font-weight: 700;
        @media screen and (max-width:800px) {
            font-size: 1.5rem;
        }
    }

    p:last-of-type{
        color: #929090;
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width:800px) {
            font-size: 1rem;
        }
    }
}