.heading{
    /* height: 400px; */
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-self: stretch; 
    padding: 1.5rem 3rem;
    /* background-repeat: no-repeat; */
    .title{
        font-size: 35px;
        font-weight: 700;
        color: #FEFEFE;
        @media screen and (max-width: 600px) {
            &{
                font-size: 22px;
                padding-bottom: .3rem;
            }
        }
    }
    .description{
        font-size: 30px;
        color: #FEFEFE;
        font-weight: 500;
        @media screen and (max-width: 600px) {
            &{
                font-size: 17px;
            }
        }
    }
    .button{
        button{
            font-size: 18px;
            font-weight: 500;
            padding: 12px 20px;
            border-radius: 10px;
            outline: none;
            cursor: pointer;
            border: 1px solid #000;
            background: inherit;
            color: #000;
            letter-spacing: 0.05em;
            background: #FFF9F0;
            /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */

        }
    }
}
.cards{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2.5rem 3rem 1.5rem;
    align-self: stretch;
    flex-wrap: wrap;
    .card{
        width: 238.438px;
        height: 245px;
        border-radius: 17.883px;
        border: 0.596px solid #000;
        display: flex;
        flex-direction: column;
        /* padding: 2.5rem; */
        .image{
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
        }
        .title{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            padding: .5rem 15% 1.5rem ;
        }
    }
}
