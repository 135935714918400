
.contact{
    width: 62%;
    margin: auto;
    background-color: #fff;
    padding: 1.5rem 0 2rem;
    @media screen and (max-width: 800px) {
        & {
            width: 100%;
            padding: 1.5rem 1.5rem 2rem;
        }         
    }
    & form{
        display: flex;
        flex-direction: column;
        align-self: stretch;
    }
    & .content{
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: .5rem;
        & .inputList{
            display: flex;
            gap: 2.5rem;
            align-self: stretch;
            & .col{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-self: stretch;
                gap: .5rem;
                @media screen and (max-width: 768px) {
                    & {
                        width: 100%;
                        margin-bottom: 10px;
                    }         
                }
            }
            @media screen and (max-width: 768px) {
                & {
                    flex-direction: column;
                    gap: 0;
                    margin-bottom: 0;
                }         
            }
        }
        .inputItem{
            display: flex;
            flex-direction: column;
            align-self: stretch;
        }
        .title{
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.05em;
            display: flex;
            margin-bottom: 5px;
            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        .input{
            display: flex;
            align-self: stretch;
            padding: 12px;
            background: rgba(218, 213, 213, 0.4);
            border-radius: 6px;
            border: none;
            font-size: 16px;
            letter-spacing: 0.05em;
            font-weight: 500;
            &::placeholder{
                color: #B1B2B5;
            }
            @media screen and (max-width: 768px) {
                font-size: 12px;
                padding: 15px 10px;
            }
            &:focus{
                outline-color: #2A456E !important;
            }
        }
        & .TxtArea{
            margin-bottom: 10px;
            .input{
                padding: 12px;
                min-height: 209px;
                min-width: 100%;
                max-width: 100%;
            }
        }
        .Send{
            display: flex;
            justify-content: flex-end;
            button{
                // width: 236px;
                // height: 66px;
                font-size: 18px;
                font-weight: 500;
                padding: 12px 3rem;
                border-radius: 10px;
                letter-spacing: 0.05em;
                outline: none;
                cursor: pointer;
                background-color: #000;
                color: #FFF;
                border: none;
            }

        }
    }
}