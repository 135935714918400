.navbar {
  @media screen and (max-width: 1099px) {
    display: none;
  }
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  & .announce {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 10px;
    & p {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .header {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    flex: 1;
    padding: 1.5rem;
    .logo {
      cursor: pointer;
      img {
        height: 100px;
      }
    }
    & .menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;
      & .register {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & .buttons {
          display: flex;
          gap: 8px;
          & .button {
            font-size: 12px;
            font-weight: 500;
            padding: 8px 16px;
            border-radius: 6px;
            letter-spacing: 0.05em;
            outline: none;
            cursor: pointer;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          & .Signup {
            background-color: #000;
            color: #fff;
            border: none;
          }
          & .Login {
            border: 1px solid #000;
            background: inherit;
            color: #000;
          }
        }
        & .Cart {
          text-decoration: none;
          font-size: 12px;
          color: #1e1e1e;
          font-weight: 400;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 10px;
          & .ShoppingCart {
            width: 20px;
            height: 20px;
            color: #1c1b1f;
          }
          & .Count {
            color: #1e1e1e;
            background-color: #eda415;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            text-align: center;
            font-size: 11px;
            font-weight: 400;
            margin-left: 3px;
            margin-right: 5px;
          }
          @media screen and (max-width: 768px) {
            & span {
              display: none;
            }
            & {
              margin-left: 0;
            }
            &.none {
              display: none;
            }
          }
        }
      }
      & .links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 2rem;
        margin: 0;
        list-style: none;
        @media screen and (max-width: 768px) {
          & {
            display: none;
            padding: 0;
            background-color: #f8f8ff;
          }
          &.show {
            display: block;
          }
        }
        & .selected {
          font-size: 18px;
          font-weight: 700;
          color: #000;
          text-decoration: line-through;
          transition: 0.1s ease;
          & i {
            display: none;
            position: absolute;
            top: 10px;
            right: 13%;
            transform: translateY(50%);
          }
        }
        & .link {
          position: relative;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          color: #000;
          text-decoration: none;
          transition: 0.1s ease;
          & i {
            display: none;
            position: absolute;
            top: 10px;
            right: 13%;
            transform: translateY(50%);
          }
          &:hover {
            color: #888;
          }
          @media screen and (max-width: 768px) {
            & {
              display: block;
              padding: 13.5px 50px;
              font-size: 12px;
              font-weight: 500;
              line-height: 17px;
            }
            &:hover {
              background: #d4dbde;
            }
            & i {
              font-size: smaller;
              display: block;
            }
          }
          &.Btn {
            display: none;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            height: 170px;
            @media screen and (max-width: 768px) {
              display: flex;
            }
          }
        }
        & .button {
          @media screen and (max-width: 768px) {
            & {
              border-radius: 5px !important;
              margin: 0 10px;
              min-width: 140px;
              padding: 9px 0px !important;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__menuToggle {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      display: block;
      background-color: transparent;
      border: none;
      font-size: 18px;
      padding: 10px 0;
      & i {
        color: #1c1b1f;
      }
      &.menuHide {
        display: none;
      }
    }
  }
  &__menuToggle:last-of-type {
    margin-right: 30px;
  }
}
.navbarMobile {
  @media screen and (min-width: 1100px) {
    display: none;
  }
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  & .announce {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    & p {
      font-size: 19.075px;
      font-weight: 500;
    }
    @media screen and (max-width: 800px) {
      padding: 0.5rem;
      & p {
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
  & .middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    & .toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 120px;
      }
    }
    & .close {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0.5rem;
    }
    & .Cart {
      text-decoration: none;
      font-size: 14px;
      color: #1e1e1e;
      font-weight: 400;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      & .ShoppingCart {
        font-size: 24px;
        color: #1c1b1f;
      }
      & .Count {
        color: #1e1e1e;
        background-color: #eda415;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        text-align: center;
        font-size: 11px;
        font-weight: 400;
        margin-left: 4px;
        margin-right: 10px;
      }
      @media screen and (max-width: 1100px) {
        & span {
          display: none;
        }
        & {
          margin-left: 0;
        }
        &.none {
          display: none;
        }
      }
    }
    & .none {
      display: none;
    }
  }
  & .header {
    display: flex;
    align-self: stretch;
    & .menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;
      width: 100%;
      transition: 1s ease-in-out;
      & .links {
        display: flex;
        flex-direction: column;
        list-style: none;
        align-self: stretch;
        & li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          padding: 1rem 3rem;
          background: rgb(255, 249, 240);
          cursor: pointer;
          &.selected {
            color: #fff;
            background-color: #000;
            svg {
              fill: white;
            }
          }
          &.link {
            color: #000;
            text-decoration: none;
          }
          p {
            font-size: 12px;
            font-weight: 400;
          }
          &:hover {
            color: #fff;
            background-color: #000;
            svg {
              fill: white;
            }
          }
          svg {
            fill: #000;
          }
        }
      }
      & .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 10px;
        & button {
          font-size: 1rem;
          font-weight: 400;
          padding: 10px 40px;
          border-radius: 6px;
          outline: none;
          cursor: pointer;
        }
        & .Signup {
          background-color: #000;
          color: #fff;
          border: none;
          a {
            color: #fff;
            text-decoration: none;
          }
        }
        & .Login {
          border: 1px solid #000;
          background: inherit;
          a {
            color: #000;
            text-decoration: none;
          }
        }
      }
    }
    & .closeMenu {
      height: 0;
      overflow: hidden;
      transition: height 1s ease-in-out;
    }
  }
}
