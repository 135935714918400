.goToDetails {
  text-decoration: none;
  // @media screen and (max-width: 768px) {
  //   & {
  //     width: 47.5%;
  //     min-height: auto;
  //   }
  // }
}
.card {
  overflow: hidden;
  width: 310.599px;
  height: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // padding-bottom: 1rem;
  @media screen and (max-width: 768px) {
    & {
      width: 139px;
      height: 234px;
    }
  }
  &__image {
    display: flex;
    align-self: stretch;
    flex: 1 0 0;
    position: relative;
    background: rgba(218, 213, 213, 0.4);
    @media screen and (max-width: 768px) {
      & {
        height: 197px;
      }
    }
    & img {
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease-in-out;
      object-fit: contain;
      transform: scale(1);
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      will-change: transform;
      object-fit: cover;
    }
    &Overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(1.2);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        will-change: transform;
        opacity: 0;
        object-fit: cover;
      }
    }
  }
  &:hover {
    .card__imageOverlay img {
      transform: scale(1);
      opacity: 1;
    }
    .card__image .img {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    @media screen and (max-width: 768px) {
      & {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  &__discreption {
    display: flex;
    gap: 60px;
    color: #929090;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    @media screen and (max-width: 768px) {
      & {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  & a {
    font-size: smaller;
    text-decoration: none;
    & i {
      font-size: smaller;
      padding: 5px;
    }
  }
}
