.links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0px 3rem;
  & a {
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    color: #000;
    padding-right: 20px;
    &:hover {
      color: #002BBC;
      text-decoration: line-through;
    }
    &.selected{
      color: #002BBC;
      text-decoration: line-through;
    }
  }
  @media screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;
  width: 86%;
  margin: 20px auto;
  display: none;
  @media screen and (max-width: 768px) {
    & {
      display: block;
      margin-bottom: 0;
    }
  }
  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2a456e;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 10px 20px;
    width: 100%;
    height: 37px;
    text-align: left;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 12px;
    & i {
      transition: all 0.3s ease-out;
      font-weight: bold;
      &.angle__down {
        transform: rotate(180deg);
      }
    }
  }
  &__content {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    opacity: 0;
    padding: 20px 20px 10px;
    border-radius: 8px;
    transition-property: all, z-index, box-shadow;
    transition-duration: 0.3s, 3s, 1s;
    transition-timing-function: ease-out, ease-out, ease-in;
    &.open {
      opacity: 1;
      z-index: 1;
    }
    & a {
      font-weight: 500;
      font-size: 12px;
      padding-top: 2px;
      display: block;
      text-decoration: none;
      color: #000;
    }
  }
}
.spliter {
  margin-top: 1.5rem;
  @media screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
}
