* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
p {
  margin-block-end: 0;
  margin-block-start: 0;
}
a {
  text-decoration: none;
}
