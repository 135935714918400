.items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  @media screen and (max-width: 768px) {
    & {
      gap: 4%;
    }
  }
  .card {
    overflow: hidden;
    width: 24.33%;
    margin-bottom: 10px;
    text-decoration: none;
    @media screen and (max-width: 768px) {
      & {
        width: 48%;
        min-height: auto;
        margin-bottom: 15px;
      }
    }
    &__image {
      border-radius: 20px;
      height: 380px;
      background: rgba(218, 213, 213, 0.4);
      margin-bottom: 10px;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        height: 197px;
      }
      & img {
        width: 100%;
        pointer-events: none;
        height: 100%;
        object-fit: cover;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      @media screen and (max-width: 768px) {
        & {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    &__button {
      color: #929090;
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        & {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    & a {
      font-size: smaller;
      text-decoration: none;
      & i {
        font-size: smaller;
        padding: 5px;
      }
    }
  }
}
