.products {
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3rem;
    @media screen and (max-width: 800px) {
      padding: 1.5rem;
      align-items: flex-start;
    }    
    .text{
      @media screen and (max-width: 800px) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
      p:first-of-type{
        font-size: 35px;
        font-weight: 700;
        @media screen and (max-width: 800px) {
          font-size: 1.5rem;
        }
      }
      p:last-of-type{
          color: #929090;
          font-size: 18px;
          font-weight: 500;
          @media screen and (max-width: 800px) {
            font-size: 16px;
          }
      }
    }
    button{
      font-size: 18px;
      font-weight: 500;
      padding: 10px 1.5rem;
      border-radius: 10px;
      letter-spacing: 0.05em;
      outline: none;
      cursor: pointer;
      background-color: #000;
      color: #FFF;
      border: none;
      @media screen and (max-width: 800px) {
        font-size: 14px;
        letter-spacing: 0.03em;
        width: fit-content;
        padding: 6px 1rem;
        min-width: 135px;
      }
    }
  }
  &__content {
    // padding-top: 50px;
    padding: 0px;

    @media screen and (max-width: 800px) {
      padding: 0pc 0rem 0rem;
    }
  }
  .categories{
    padding: 0px 3rem;
    @media screen and (max-width: 800px) {
      padding: 0px 1.5rem;
    }
  }
  .contact{
    padding: 3rem 0 2rem;
    @media screen and (max-width: 800px) {
      padding: 1.5rem 0px 0px;
    }
  }
}
.title{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1.5rem 3rem ;
  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
  p{
      font-size: 24px;
      font-weight: 700;
  }   
  span{
      color: #929090;
      font-size: 24px;
      font-weight: 600;
  }
}