
.footer{
    display: flex;
    flex-direction: column;
    padding: 1.5rem 3rem 2rem; 
    gap: 1.5rem;
    align-self: stretch;
    @media screen and (max-width: 800px) {
        & {
            flex-direction: column;
            padding: 2rem 1.5rem 1rem;        
        }         
    }
    & .header{
        & p{
            font-family: '"adobe Jenson Pro';
            font-size: 1.5rem;
            font-weight: 700;
            @media screen and (max-width:800px) {
                font-size: 18px;
            }
        }
    }
    .body{
        display: flex;
        align-items: start;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
            & {
                flex-direction: column;        
                gap: 1.5rem;
            }         
        }
        .left{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            .connect{
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                & i{
                    font-size: 30px;
                    margin: 5px;
                    @media screen and (max-width: 768px) {
                        & {
                            font-size: 18px;
                        }         
                    }
                }
            }
            .help{
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 25px;
                & a{
                    text-decoration: none;
                    color: #000;
                    &:hover{
                        color: #2A456E;
                    }
                }        
            }
        }
        & .right{
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            @media screen and (max-width: 800px) {
                & {
                    align-items: flex-start;        
                    align-self: stretch;
                }         
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 16px;
                @media screen and (max-width: 800px) {
                    & {
                        align-self: stretch;
                        width: 100%;
                    }         
                }
            }
            & form{
                display: flex;
                flex-direction: column;
                gap: 22px;
                .Subscribe{
                    display: flex;
                    justify-content: flex-end;
                    button{
                        font-size: 1rem;
                        font-weight: 500;
                        padding: 10px 20px;
                        border-radius: 10px;
                        letter-spacing: 0.05em;
                        outline: none;
                        cursor: pointer;
                        background-color: #000;
                        color: #FFF;
                        border: none;
                    }

                }
                & input[type="email"]{
                    display: flex;
                    border: 1px solid #1D2939;
                    border-radius: 15px;
                    padding: 15px 10px;
                    background-color: transparent;
                    letter-spacing: 0.05em;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    &::placeholder{
                        color: #B1B2B5;
                    }
                    &:focus{
                        outline-color: #2A456E !important;
                        border: none;
                        
                    }    
                }
                @media screen and (max-width: 768px) {
                    & {
                        width: 100%;
                    }         
                }
    
            }
        }
        .title{
            font-weight: 900;
            font-size: 18px;
            padding-bottom: 6px;
            @media screen and (max-width: 768px) {
                & {
                    font-weight: 70px;
                    font-size: 14px;
                    line-height: 21px;
                }         
            }
        }
    }

    
}

.face{
    color: #039BE5;
}

.insta{
    background: #fff;
    color: #E1306C;
}