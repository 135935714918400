.header{
  padding: 1.5rem 3rem;
  @media screen and (max-width:800px) {
    padding: 1.5rem 1.5rem;
  }
  p:first-of-type{
    font-size: 35px;
    font-weight: 700;
    @media screen and (max-width:800px) {
      font-size: 1.5rem;
    }  
  }
  p:last-of-type{
    color: #929090;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width:800px) {
      font-size: 1rem;
    }  
  }
  button{
    font-size: 18px;
    font-weight: 500;
    padding: 8px 1rem;
    border-radius: 10px;
    letter-spacing: 0.05em;
    outline: none;
    cursor: pointer;
    background-color: #000;
    color: #FFF;
    border: none;
  }
}