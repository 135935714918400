.about{
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
}
.header{
    padding: 1.5rem 3rem;
    @media screen and (max-width:800px) {
        padding: 1.5rem 1.5rem;
    }
    p:first-of-type{
        font-size: 35px;
        font-weight: 700;
        @media screen and (max-width:800px) {
            font-size: 1.5rem;
        }
    }
    p:last-of-type{
        color: #929090;
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width:800px) {
            font-size: 1rem;
        }
    }
}
.content{
    display: flex;
    flex-direction: column;
    padding: 1.5rem 7rem 1.5rem 3rem;
    gap: 2rem;
    @media screen and (max-width:800px) {
        padding: 1.5rem;
        gap: 1.5rem;
    }
    p{
        font-size: 18px;
        font-weight: 400;
        @media screen and (max-width:800px) {
            font-size: 16px; 
        }
    }
}
/* .button{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 15px 40px;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    border: 1px solid #000;
    background: inherit;
    color: #000;
} */