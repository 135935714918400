.customProducts{
    display: flex;
    flex-direction: column;
}
.header{
    padding: 1.5rem 3rem;
    @media screen and (max-width:800px) {
        padding: 1.5rem 1.5rem;
    }
    p:first-of-type{
        font-size: 35px;
        font-weight: 700;
        @media screen and (max-width:800px) {
            font-size: 1.5rem;
        }
    }
    p:last-of-type{
        color: #929090;
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width:800px) {
            font-size: 1rem;
        }
    }
}
.visit{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 3rem;
    gap: 1rem;
    @media screen and (max-width:800px) {
        padding: 1.5rem;
    }
    p{
        font-size: 30px;
        font-weight: 500;
        @media screen and (max-width:800px) {
            font-size: 20px;
        }
    }
    .items{
        display: flex;
        flex-wrap: wrap;
        align-self: stretch;
        gap: 3rem;
        padding: 2rem 0;
        .item{
            width: 354px;
            height: 340px;
            border-radius: 30px;
            background: #FFF;
        }
    }
}
.button{
    font-size: 18px;
    font-weight: 500;
    padding: 12px 40px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    border: 1px solid #000;
    background: inherit;
    color: #000;
    letter-spacing: 0.05em;
    @media screen and (max-width:800px) {
        font-size: 16px;
        padding: 10px 30px;
    }
}