.frame{
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 2rem 0px;
    border-radius: 30px;
    border: 4px solid #000;
    position: relative;
    gap: 1.5rem;
    border-radius: 15px;
    .title{
        padding: .5rem 1rem;
        text-align: center;
        position: absolute;
        top: 0;
        width: 600px;
        left: 50%;
        border-radius: 30px;
        background: #000;
        color: #FFF;
        transform: translate(-50%, -50%);
        font-size: 28px;
        font-weight: 700;
        @media screen and (max-width: 800px) {
            width: max-content;
            padding:.2rem 2rem .5rem ;
            font-size: 20px;    
        }
    }
    .container{
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 1.5rem;
        padding: 1.5rem 0;
        .item{
            display: flex;
            flex-direction: column;
            align-self: stretch;
            gap: 1.5rem;
        }
        .itemTitle{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 30px 30px 0px;
            border: 4px solid #000;
            border-left: 0;
            width: 500px;
            font-size: 18px;
            font-weight: 500;
            padding: .5rem 0;
            @media screen and (max-width: 800px) {
                width: fit-content;
                padding: .3rem 12px .5rem;
                font-size: 1rem;
            }
            p{
                text-align: center;
            }
        }
        .content{
            display: flex;
            padding: 0 3rem;
            gap: 2rem;
            @media screen and (max-width: 800px) {
                padding: 0 1.5rem;
            }
            .avatar{
                img{
                    width: 40px;
                }
            }
            .text{
                p{
                    font-size: 18px;
                    font-weight: 400;
                    @media screen and (max-width: 800px) {
                        font-size: 14px;
                    }
                }
            }
        }
        .divider{
            padding: 0 3rem;
            @media screen and (max-width: 800px) {
                padding: 0 1.5rem;
            }
        }
    }
}